<template>
  <div class="view">
    <div v-if="debugVariables">
      {{ labels.content_status }}: {{ status }}<br />
      {{ labels.is_reviewer }}: {{ isReviewer }}<br />
      {{ labels.is_author }}: {{ isAuthor }}<br />
      {{ labels.is_review_requested }}: {{ isReviewRequested }}<br />
      {{ labels.is_in_review }}: {{ isInReview }}<br />
      {{ labels.editor_mode }}: {{ editorMode }}<br />
      {{ labels.module_page_data }}: {{ modulePageData }}
    </div>

    <router-view
      name="module_content"
      :content-sidebar-data="contentSidebarData"
      :status="status"
      :modulePageData="modulePageData"
      ref="module-container"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPlus,
  faMinus,
  faChevronUp,
  faChevronDown,
  faCubes,
  faArrowLeft,
  faClipboardList,
} from "@fortawesome/free-solid-svg-icons";
import {
  customExternalLink,
  customArrows,
  customTrash,
  customMonitor,
  customMobile,
  customPending,
  customHistory,
} from "@/assets/icons";
library.add(
  customExternalLink,
  customArrows,
  customTrash,
  customMonitor,
  customMobile,
  customPending,
  customHistory,
  faClipboardList,
  faArrowLeft,
  faCubes,
  faPlus,
  faMinus,
  faChevronUp,
  faChevronDown
);

export default {
  name: "Main",
  components: {},
  data() {
    return {
      firstRequest: true,
      contentSidebarData: {
        leftStatus: false,
        rightStatus: false,
        leftSidebarAction: this.toggleLeftSidebar,
        rightSidebarAction: this.toggleRightSidebar,
      },
      debugVariables: false,
    };
  },
  computed: {
    ...mapGetters("helper", ["labels"]),
    ...mapState("helper", ["user_id", "admin_roles"]),
    ...mapState("_module", [
      "moduleContent",
      "editorMode",
      "stateSingleData",
      "singleData",
      "filesToSave",
    ]),

    countChanges() {
      if (this.$route.name === "module-editor")
        return this.$store.getters["_module/countContentChanges"];

      return this.$store.getters["_module/countSettingsChanges"];
    },
    singleModule() {
      return this.$store.state._module.singleData;
    },
    reviewStatus() {
      return this.singleModule.review?.status;
    },
    requestAction() {
      return this.goToRequest();
    },
    isRequestHidden() {
      if (this.editorMode === "reviewer") return true;
      if (this.reviewStatus !== 0) return true;
      if (this.singleData?.contentMeta?.kind == 1) return true;

      return false;
    },
    isReviewRequested() {
      return this.singleData?.review?.status === 1;
    },
    isInReview() {
      return this.singleData?.review?.status === 2;
    },
    isReviewer() {
      let flag = false;

      if (this.admin_roles) {
        if (Array.isArray(this.admin_roles) && this.admin_roles.includes("reviewer")) {
          this.singleData.reviewers?.forEach((reviewer) => {
            if (reviewer._id == this.user_id) {
              flag = true;
            }
          });
        }
      }

      return flag;
    },
    isAuthor() {
      let flag = false;

      if (this.admin_roles) {
        if (Array.isArray(this.admin_roles) && this.admin_roles.includes("author")) {
          this.singleData.authors?.forEach((author) => {
            if (author._id == this.user_id) {
              flag = true;
            }
          });
        }
      }

      return flag;
    },
    canApproveReview() {
      if (
        this.editorMode === "reviewer" &&
        this.singleData?.review?.status === 2
      )
        return !true;

      return !false;
    },
    getCreatedDate() {
      return {
        date: this.singleModule.content?.created?.date?.slice(0, 10),
        time: this.singleModule.content?.created?.date?.slice(11, 16),
      };
    },
    pageHeaderData() {
      let output = {
        preTitle: this.labels.module_title,
        title: this.singleModule.title,
        btnList: [
          {
            type: "icon",
            action: this.toggleRightSidebar,
            icon: "clipboard-list",
            class: "btn-type-icon d-lg-none",
          },
          {
            type: "label",
            link: { name: "module-editor" },
            label: this.labels.editor,
            class: `btn-type-label ${
              this.$route.name === "module-editor" ? "current" : ""
            }`,
          },
          {
            type: "label",
            link: { name: "module-sub-content" },
            label: this.labels.sub_content,
            class: `btn-type-label ${
              this.$route.name === "module-sub-content" ? "current" : ""
            }`,
          },
          {
            type: "label",
            link: { name: "module-authors" },
            action: () => {},
            label: this.labels.authors,
            class: `btn-type-label ${
              this.$route.name === "module-authors" ? "current" : ""
            }`,
          },
          {
            type: "label",
            link: { name: "module-media" },
            action: () => {},
            label: this.labels.media,
            class: `btn-type-label ${
              this.$route.name === "module-media" ? "current" : ""
            }`,
            hidden: !this.isAuthor,
          },
          {
            type: "label",
            link: { name: "module-analytics" },
            label: this.labels.analytics,
            class: `btn-type-label ${
              this.$route.name === "module-analytics" ? "current" : ""
            }`,
          },
          {
            type: "icon",
            action: () => [
              this.goToHistory(),
              this.getModuleContentMt("pending"),
            ],
            style: { fontSize: "1.3rem" },
            icon: "custom-pending",
            class: `btn-type-icon ${
              this.status === "pending" ? "current" : ""
            }`,
            hidden: !this.isAuthor,
          },

          {
            type: "icon",
            action: () => [
              this.goToRequest(),
              this.getModuleContentMt("pending"),
            ],
            icon: "custom-history",
            class: `btn-type-icon ${
              this.status === "history" ? "current" : ""
            }`,
            hidden: this.isRequestHidden,
          },
          {
            type: "button",
            action: this.reviewStartMt,
            label: this.labels.start_review,
            variant: "btn-green",
            class: "btn-type-button",
            disabled: false,
            hidden: this.canStartReview,
          },
          {
            type: "button",
            action: this.reviewApproveMt,
            label: this.labels.approve_review,
            variant: "btn-green",
            class: "btn-type-button",
            disabled: false,
            hidden: this.canApproveReview,
          },
          {
            type: "label",
            link: {
              name: "bundle-module-preview",
              params: {
                module_id: this.singleData._id || 0,
                monetization_bundle_id: this.singleData.monetizationBundle || 0
              },
            },
            label: this.labels.preview,
            class: "btn-type-label",
            hidden: !this.isAuthor,
            target: "_blank"
          },
          {
            type: "icon",
            action: () => this.goToSettings(),
            icon: "custom-cog",
            class: `btn-type-icon ${
              this.$route.name === "module-settings" ? "current" : ""
            }`,
          },
          {
            type: "icon",
            style: { fontSize: "0" },
          },

          // The way status of content is changed now different so this button does nothing
          // {
          // 	type: 'button',
          // 	action: () => [this.getModuleMt, this.status = 'change'],
          // 	label: this.labels.current_version,
          // 	variant: 'btn-green',
          // 	class: 'btn-type-button',
          // 	hidden: this.$route.name !== 'module-editor' || this.status === 'change'
          // },
          {
            type: "button",
            action: this.discardChanges,
            label: this.labels.discard_changes,
            variant: "btn-danger",
            class: "btn-type-button",
            hidden: this.discardChangesHidden,
          },
          {
            type: "button",
            action: this.updateModule,
            label: this.labels.save,
            variant: "btn-green",
            class: "btn-type-button",
            //disabled: this.countChanges.saveBtnDisabled
            disabled: false,
            hidden: this.saveBtnDisabled,
          },
        ],
        history: {
          preTitle: this.labels.module_title,
          title: this.singleModule.title,
          versionOf: this.labels.version_of,
          varsionDate: this.getCreatedDate.date,
          varsionTime: this.getCreatedDate.time,
          linkList: [
            {
              type: "label",
              link: {
                name: "module-editor",
                params: { kind: "module-editor" },
              },
              label: this.labels.go_to_the_original,
              class: `btn-type-label ${
                this.$route.name === "go-original" ? "current" : ""
              }`,
            },
          ],
          btnList: [
            {
              type: "button",
              action: () => [this.goToAddNote()],
              label: this.labels.add_note,
              variant: "btn-green",
              class: "btn-type-button",
            },
            {
              type: "button",
              action: () => [this.goToSearchNotes()],
              label: this.labels.notes,
              variant: "btn-danger",
              class: "btn-type-button",
              // hidden: this.countChanges.saveBtnDisabled
            },
            {
              type: "button",
              action: () => [this.updateModule()],
              label: this.labels.save,
              variant: "btn-green",
              class: "btn-type-button",
              disabled: false,
            },
            {
              type: "button",
              action: () => [this.approveReview()],
              label: this.labels.approve,
              variant: "btn-orange",
              class: "btn-type-button",
              //disabled: this.countChanges.saveBtnDisabled
              disabled: false,
            },
          ],
        },
      };

      // Only show submodule toggle button if we are at editor page
      if (this.$route.name === "module-editor")
        output.btnList.unshift({
          type: "icon",
          action: this.toggleLeftSidebar,
          icon: "cubes",
          class: "btn-type-icon d-min-1200-none",
        });

      return output;
    },
    status() {
      //console.log(`isAuthor: ${this.isAuthor}, isReviewer: ${this.isReviewer}, isInReview: ${this.isInReview}, isReviewRequested: ${this.isReviewRequested}`);

      if (this.admin_roles === false) return "wait";

      if (
        this.editorMode === "author" &&
        Array.isArray(this.singleData?.authors)
      ) {
        if (this.isAuthor) return "change";

        return "current";
      }

      if (
        this.editorMode === "reviewer" &&
        Array.isArray(this.singleData?.authors)
      ) {
        if (this.isReviewer && !this.isInReview && !this.isReviewRequested)
          return "current";

        if (this.isReviewer && (this.isInReview || this.isReviewRequested))
          return "change";

        return "current";
      }

      if (!Array.isArray(this.singleData?.authors)) {
        // We don't have any info about module yet, let's load basic info about it, without content, to understand who is linked as an author and who as a reviewer
        return "base";
      }

      // If code got to this line it means that some scenario is not predicted and at current stage no correct status can be assigned
      //console.log('Set default status');
      return "wait";
    },
    canStartReview() {
      if (
        this.isReviewer &&
        this.isReviewRequested &&
        this.editorMode === "reviewer"
      )
        return false;

      return true;
    },
    saveBtnDisabled() {
      if (this.$route.name === "module-settings") {
        if (this.hasRole("super") || this.isAuthor)
          return this.countChanges.saveBtnDisabled;

        return true;
      } else if (this.$route.name === "module-media") {
        if (this.filesToSave?.length) return false;

        return true;
      } else {
        if (this.isAuthor) {
          if (this.isReviewRequested) return true;

          return this.countChanges.saveBtnDisabled;
        }

        if (this.isReviewer) {
          if (this.isInReview) return this.countChanges.saveBtnDisabled;

          return true;
        }
      }

      return true;
    },
    cancelReviewPossible() {
      if (this.isAuthor && this.isReviewRequested) return true;
      if (this.isAuthor && this.isInReview) return true;
      if (this.isReviewer && this.isInReview) return true;

      return false;
    },
    modulePageData() {
      return {
        status: this.status,
        canApproveReview: this.canApproveReview,
        isAuthor: this.isAuthor,
        isReviewer: this.isReviewer,
        isInReview: this.isInReview,
        isReviewRequested: this.isReviewRequested,
        reviewStatus: this.reviewStatus,
        cancelReviewPossible: this.cancelReviewPossible,
        isModuleContentPresent: this.isModuleContentPresent,
        tabsContentCanBeShown: this.tabsContentCanBeShown,
        hasRole: (role) => this.hasRole(role),
        contentKind: this.contentKind
      };
    },
    isModuleContentPresent() {
      if (typeof this.singleData?.content === "object") return true;

      return false;
    },
    tabsContentCanBeShown() {
      if (this.status !== "wait" && this.status !== "base") return true;

      if (Array.isArray(this.admin_roles) && this.admin_roles.includes("creator")) return true;

      return false;
    },
    discardChangesHidden() {
      if (this.$route.name === "module-media" && this.filesToSave?.length) {
        return false;
      }

      return this.countChanges.saveBtnDisabled;
    },
    contentKind() {
      if (this.singleData?.contentMeta) return this.singleData.contentMeta.kind;

      return -1;
    }
  },
  methods: {
    ...mapMutations("helper", {
      setPageHeaderData: "setPageHeaderData",
    }),
    ...mapMutations("_module", {
      resetContentBlock: "resetContentBlock",
      setContentResponseWaiting: "setContentResponseWaiting",
      resetLocalMedia: "resetLocalMedia",
    }),
    ...mapActions("_module", {
      update: "update",
      getModule: "getSingle",
      getModuleContent: "getModuleContent",
      reviewApprove: "reviewApprove",
      reviewStart: "reviewStart",
    }),
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    goToHistory() {
      if (this.$route.name !== "module-editor-history") {
        this.$router.push({
          name: "module-editor-history",
          query: { page: this.$route.query.page },
        });
      }
    },
    goToRequest() {
      if (this.$route.name !== "module-editor-request") {
        this.updateModule();
        this.$router.push({
          name: "module-editor-request",
          query: { page: this.$route.query.page },
        });
      }
    },
    goToSettings() {
      if (this.$route.name !== "module-settings") {
        this.$router.push({ name: "module-settings" });
      }
    },
    goToAddNote() {
      if (this.$route.name !== "module-editor-add-note") {
        this.$router.push({ name: "module-editor-add-note" });
      }
    },
    updateModule() {
      if (this.$route.name === "module-media") {
        this.$refs["module-container"]["saveSelectedFiles"]();

        return;
      }

      let data = {
        id: this.$route.params.id,
        payload: {},
      };
      if (this.$route.name === "module-editor")
        data.payload.content = this.moduleContent;

      switch (this.$route.name) {
        case "module-editor":
          data.payload.content = this.moduleContent;
          break;
        case "module-settings":
          data.payload = this.stateSingleData;
          break;
      }

      this.update(data)
        .then((res) => {
          console.log(res);
          if (res.success) {
            this.getModuleMt();
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    getModuleMt() {
      if (
        this.admin_roles === false ||
        (this.status === "wait" && !this.firstRequest)
      )
        return;

      if (this.firstRequest) this.firstRequest = false;

      let data = {
        id: this.$route.params.id,
        state: this.status,
      };

      this.getModule(data)
        .then((res) => {
          if (res.success) {
            // console.log("Got response about single module:", res);
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    getModuleContentMt(kind) {
      this.getModuleContent(kind)
        .then((res) => {
          if (res.success) {
            console.log("Got response about content of single module:", res);
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    toggleLeftSidebar() {
      this.contentSidebarData.leftStatus = !this.contentSidebarData.leftStatus;
    },
    toggleRightSidebar() {
      this.contentSidebarData.rightStatus =
        !this.contentSidebarData.rightStatus;
    },
    reviewStartMt() {
      this.reviewStart({ id: this.$route.params.id })
        .then((res) => {
          if (res.success) {
            console.log("reviewStart", res);
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    reviewApproveMt() {
      console.log(this.$route.params.id);
      console.log(this.stateSingleData);
      let data = {
        id: this.$route.params.id,
        content_id: this.stateSingleData?.content?._id,
      };

      this.reviewApprove(data)
        .then((res) => {
          if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    hasRole(role) {
      if (Array.isArray(this.admin_roles) && this.admin_roles.includes(role))
        return true;

      return false;
    },
    discardChanges() {
      if (this.$route.name === "module-media") {
        this.resetLocalMedia();
      } else {
        this.getModuleMt();
      }
    },
  },
  created() {
    this.$root.$refs.modulePageContainer = this;
  },
  beforeMount() {
    this.setPageHeaderData({ preTitle: "", title: "", btnList: [] });
  },
  mounted() {
    this.setPageHeaderData(this.pageHeaderData);
    this.getModuleMt();
  },
  beforeDestroy() {
    this.setPageHeaderData({});
    this.resetContentBlock();
    this.setContentResponseWaiting(false);
  },
  watch: {
    pageHeaderData(value) {
      this.setPageHeaderData(value);
    },
    status() {
      this.getModuleMt();
    },
  },
};
</script>

<style lang="scss" scoped></style>
